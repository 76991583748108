import ImageComponent from "../../utils/ImageComponent";
import TutorialAreaCard from "./TutorialAreaCard";
import TutorialAreaSocialIcons from "./TutorialAreaSocialIcons";

const data = [
  {
    id: 1,
    image: "img/img1.png",
    url: "https://www.youtube.com/watch?v=26YV1bvOqJM",
    playImage: "img/play.svg",
    date: "Version 3",
    heading: "Data operations",
    subHeading:
      "See how you backup your data and restore.We support data across multiple devices",
  },
  {
    id: 2,
    image: "img/img2.png",
    url: "https://www.youtube.com/watch?v=aHA1ezOtKsM",
    playImage: "img/play.svg",
    date: "Version 3",
    heading: "Settings",
    subHeading:
      " You can customize a lot of functionalities.Learn how to use settings in this video",
  },
  {
    id: 3,
    image: "img/img3.png",
    url: "https://www.youtube.com/watch?v=QqAEuNQsJY8",
    playImage: "img/play.svg",
    date: "Version 3",
    heading: "Create Parties",
    subHeading:
      "How to create parties and start adding business entries for them.",
  },

  {
    id: 4,
    image: "img/img4.png",
    url: "https://www.youtube.com/watch?v=5hdqYT1kDyU",
    playImage: "img/play.svg",
    date: "Version 3",
    heading: "Reports",
    subHeading:
      "Different type of reports can give you a lot of insignts for you business. Create reports and share as PDF/Excel with your stake holders",
  },
];

export default function TutorialArea() {
  return (
    <div className="tutorial_area">
      <img src="img/lin2.png" className="w-100 line2" alt="" />
      <div className="container">
        <div className="mt_30">
          <div className="tutorial_box">
            <div className="row align-items-center">
              <div className="col-md-6 order-md-2">
                <div className="video_thumbnail">
                  <ImageComponent src="img/img1.png" />

                  <a
                    className="play-video"
                    href="https://www.youtube.com/watch?v=pHHQonSRu5w"
                  >
                    <ImageComponent src="img/play.svg" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <h2>How does Pocket Ledger work?</h2>
                <p>
                  This video gives an overview of how the app work to make your
                  business process more effective{" "}
                </p>
                <TutorialAreaSocialIcons />
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-center mt_30">
          Watch Our Tutorials & Get Familiar
        </h2>
        <div className="row">
          {data.map((item: any) => {
            return (
              <div key={item.id} className="col-lg-6 col-md-12 col-sm-6 mt_30">
                <div className="tutorial_box">
                  <TutorialAreaCard
                    image={item.image}
                    url={item.url}
                    playImage={item.playImage}
                    date={item.date}
                    heading={item.heading}
                    subHeading={item.subHeading}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
