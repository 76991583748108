
export default function Subs() {
    return (

        <div >
            <div className="modal-header">
                <h3 className="modal-title w-100">E8 Subscriptions</h3>

            </div>
            <img src="img/line.png" className="w-100 line" alt="" />
            <div className="modal-body">

                <p><b>E8 Technologies Inc.</b> offers subscriptions in form of in app purchases. You can buy with Google play or UPI(India) only.</p>
                <p>All purchase does not have any commitment and you cancel anytime.</p>
                <p>Purchases done with google play will be processed by Google , and will be automatically deducted using your payment method monthly or yearly based on your selection</p>
<p>If you have purchased using UPI, you will get reminder 3 days before the expiry date and you have to pay it manually.</p>
<br></br>
              
                <h3>Contact Us</h3>
                <p>For any questions or concerns regarding your purchase, you may contact us using the
                    following details: </p>
                <p><b>E8 Technologies Inc.</b><br />
                   <a href="mailto:support@eeighttech.com">support@eeighttech.com</a>  </p>
                    
            </div>

        </div>

    );
}
