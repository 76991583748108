import { Link } from "react-router-dom";

export default function FooterSocialIcons() {
  return (
    <div className="fsocial_icon">
      <Link
        to="https://www.instagram.com/admin.pocketledger/"
        className="twitter"
      >
        <i className="fab fa-instagram"></i>
      </Link>
     
    </div>
  );
}
