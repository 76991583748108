import React from "react";
import ImageComponent from "../../utils/ImageComponent";

type FeatureAreaCardProps = {
  image: string;
  heading: string;
  paragraph: string;
};

export default function FeatureAreaCard(props: FeatureAreaCardProps) {
  return (
    <div className="box_content">
      <div className="icon_wrap mx-auto">
        <div className="icon_box">
          <ImageComponent src={props.image} />
        </div>
      </div>
      <h4>{props.heading}</h4>
      <p>{props.paragraph}</p>
      {/* <!-- <a href="#" className="button2">View</a> --> */}
    </div>
  );
}
