export const getErrorMessage = (errorCode: string): string => {
	switch (errorCode) {
		case "A001002":
			return "Pin do not match!";

		case "A001001":
			return "No user found for above information!";

		default:
			return "Unable to delete user. Something went wrong!";
	}
};
