type DeleteUserTypes = {
	email: string;
	pin: string;
};

export const deleteUser = async ({ email, pin }: DeleteUserTypes) => {
	const currentTime = Date.now() / 1000;
	return await fetch(`https://api.eeighttech.com/core/api/v1/account/delete`, {
		method: "POST",
		headers: {
			XApiKey: `${process.env.REACT_APP_XAPI_KEY}`,
			uid: `${email}`,
			did: `${currentTime}`,
			pin: `${pin}`,
			aid: `${currentTime}`,
			"Content-Type": "application/json",
		},
	});
};
