import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import DeleteAccount from "./pages/DeleteAccount";
import TNCModal from "./components/Modals/TNCModal";
import TnC from "./pages/Tnc";
import Privacy from "./pages/privacy";
import Subs from "./pages/subscription";
import HelpDocs from "./pages/HelpDocs";
export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/" element={<Home />} />
          <Route path="/tnc" element={<TnC />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/subscriptions" element={<Subs />} />
          <Route path="/docs" element={<HelpDocs />} />
        </Routes>
      </Router>
    </>
  );
}
