import HomeArea from "./HomeArea";
import FeatureArea from "./FeatureArea/FeatureArea";
import TutorialArea from "./TutorialArea/TutorialArea";
import WhiteListArea from "./WhiteListArea";

export default function Main() {
  return (
    <>
      <main className="overflow-hidden">
        {/* <|-- home area --> */}
        <HomeArea />

        {/* <!-- feature area --> */}
        <FeatureArea />

        {/* <!-- tutorial area --> */}
        <TutorialArea />

        {/* <!-- whitelist area --> */}
        <WhiteListArea />
      </main>
    </>
  );
}
