import React from "react";
import ImageComponent from "../utils/ImageComponent";

type ContactProps = {
  image: string;
  url: string;
  number: string;
};

export default function ContactNumbers(props: ContactProps) {
  return (
    <>
      <div className="d-inline-flex icon_text align-items-center mt_15">
        <ImageComponent src={props.image} />
        <a href={props.url}>
          <p>{props.number}</p>
        </a>
      </div>
    </>
  );
}
