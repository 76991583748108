
export default function HelpDocs() {
    return (

        <div >
            <div className="modal-header">
                <h3 className="modal-title">Pocket Ledger Feature Topics</h3>

            </div>
            <img src="img/line.png" className="w-100 line" height={15} alt="" />
            <div className="modal-body w-100" id="f1">

                <h2>User Management</h2>
                <p>Now you can share your data with your friends and parties and give access to specific area only.Try out the new <strong>User Management</strong> feature. Open menu and select User Management</p>
                <br />
                <p>You can add any number of users and share a specific business with them.Once added they will be notified.Users then can login with the email and pin
                    you have set for them. Every entry they add you as a admin will get a notification on your device . Only when you approve the entry it will
                    be committed to the business.
                </p>
                <br />
                <p>
                    You can manage multiple business in real time with your staffs.
                </p>
                <div className="modal-header"><strong>Go to Menu &gt; User Management</strong></div>

                <div>
                    <img
                        src="img/features/new_user_menu.png"
                        className="mobile-mock-up"
                        alt=""
                    />
                </div>

                <div className="modal-header"><strong>View User list and Add new user</strong></div>

                <div>
                    <img
                        src="img/features/user_list.png"
                        className="mobile-mock-up"
                        alt=""
                    />
                </div>

                <div className="modal-header"><strong>Add new user</strong></div>
                <p>Enter a name email and pin for a new user. You have to select the specific business and give permission to the areas you want.You can give only read,write or delete access. </p>

                <div>
                    <img
                        src="img/features/add_user.png"
                        className="mobile-mock-up"
                        alt=""
                    />
                </div>

            </div>

            <div className="modal-body" id="f2">

                <h2>Data Sync across multiple devices</h2>
                <p>We are happy to release multi device syncronisation in this release.If you are a paid user you are allowed to use upto <strong>50 Devices</strong> at the same time.</p>
                <br />
                <p>If you use a single business and login to multiple devices using the same login, any data update on a device will be updated to all other device in realtime.
                </p>
                <br />


            </div>

            <div className="modal-body" id="f3">

                <h2>Paid Subscription Enhancements</h2>
                <p>Now 5 days before your subscription expiry, you will get notified.</p>
                <br />
                <p>Once expired you cannot add add new entries.But all other entries will be editable.You can pay for your subscription either from Google play or paying via our QR code.We reecommend all users to pay ising Google play</p>
                <br />


            </div>

            <div className="modal-body" id="f4">

                <h2>Invoice Enhancements</h2>
                <p>You dont need to setup product to create invoice anymore.</p>
                <br />
                <p>In invoice creation page you can directly add a product for invoice line item. You can add multiple line itemd directly and create invoice pdf.</p>
                <br />

                <div>
                    <img
                        src="img/features/add_invoice.png"
                        className="mobile-mock-up"
                        alt=""
                    />
                </div>

            </div>

        </div>

    );
}
