
export default function TnC() {
	return (

			<div >
			  <div className="modal-header">
				<h3 className="modal-title w-100">Terms & Conditions</h3>

			  </div>
			  <img src="img/line.png" className="w-100 line" alt="" />
			  <div className="modal-body">
				<h4>
				  Please see below out terms and condition for the Pocket Ledger App
				</h4>
				<br />
				<p>
				  Please read this EULA carefully, as it sets out the basis upon
				  which we license the Pocket Ledger Android/IOS Application (Software)
				  for use.
				</p>
	
				<p>
				  By agreeing to be bound by this EULA, you further agree that your
				  employees OR any person you authorise to use the Software will
				  comply with the provision of this EULA. If you are entering into
				  this EULA on behalf of a company or other legal entity, you
				  represent that you have the authority to bind such entity and its
				  affiliates to these terms and conditions. If you do not have such
				  authority or if you do not agree with the terms and conditions of
				  this EULA, do not install or use the Software, and you must not
				  accept this EULA. You are bound by this EULA even if you register
				  or download for a free trial of this Software.
				</p>
				<p>
				  <strong>AGREEMENT</strong>
				</p>
				<p>
				  <strong>1. </strong>
				  <strong>Definitions</strong>
				</p>
				<p>
				  1.1 Except to the extent expressly provided otherwise, in this
				  EULA:
				</p>
				<p>
				  "<strong>Charges</strong>" means those amounts that the parties
				  have agreed in writing shall be payable by the User to the
				  Licensor in respect of this EULA;
				</p>
				<p>
				  "<strong>Documentation</strong>" means the documentation for the
				  Software developed by the Licensor and delivered or made available
				  by the Licensor to the User;
				</p>
				<p>
				  "<strong>Effective Date</strong>" means the date upon which the
				  User gives the User's express consent to this EULA, following the
				  issue of this EULA by the Licensor;
				</p>
				<p>
				  "<strong>EULA</strong>" means this end user licence agreement,
				  including any amendments to this end user licence agreement from
				  time to time;
				</p>
				<p>
				  "<strong>Force Majeure Event</strong>" means an event, or a series
				  of related events, that is outside the reasonable control of the
				  party affected (including [failures of the internet or any public
				  telecommunications network, hacker attacks, denial of service
				  attacks, virus or other malicious software attacks or infections,
				  power failures, industrial disputes affecting any third party,
				  changes to the law, disasters, explosions, fires, floods, riots,
				  terrorist attacks and wars]);
				</p>
				<p>
				  "<strong>Intellectual Property Rights</strong>" means all
				  intellectual property rights wherever in the world, whether
				  registrable or unregistrable, registered or unregistered,
				  including any application or right of application for such rights
				  (and these "intellectual property rights" include copyright and
				  related rights, database rights, confidential information, trade
				  secrets, know-how, business names, trade names, trade marks,
				  service marks, passing off rights, unfair competition rights,
				  patents, petty patents, utility models, semi-conductor topography
				  rights and rights in designs), Software includes Intellectual
				  Property Rights of 3<sup>rd </sup>
				  parties including but not limited to open source software(s) for
				  which the Licensor has sufficient rights to license its
				  intellectual property;
				</p>
				<p>
				  "<strong>Licensor</strong>" means <strong>E8 Technologies Inc.</strong>, a
				  company incorporated in India having its registered office at Pune
				</p>
				<p>
				  "<strong>Licensor Indemnity</strong>" has the meaning given to it
				  in Clause 9.1;
				</p>
				<p>
				  "<strong>Maintenance Services</strong>" means the Software
				  application monthly Updates and Upgrades;
				</p>
				<p>
				  "<strong>Term</strong>" means, in respect of this EULA, the period
				  of Term as mentioned in the Order Form starting from beginning on
				  the Effective Date;
				</p>
				<p>
				  "<strong>Services</strong>" means any services that the Licensor
				  provides to the User, or has an obligation to provide to the User,
				  under this EULA;
				</p>
				<p>
				  "<strong>Software</strong>" Pocket Ledger Android Application. It
				  has been developed by E8 Technologies Inc. to enable automation of
				  different business operations. It uses a flexible framework of
				  pluggable tools and components, which can be configured based on
				  specific needs to provide customized automation capabilities;
				</p>
				<p>
				  "<strong>Defect</strong>" means a defect, error or bug in the
				  Software having a material adverse effect on operation,
				  functionality or performance of the Software, but excluding any
				  defect, error or bug caused by or arising as a result of:
				</p>
				<p>
				  (a) Any act or omission of the User or any person authorised by
				  the User to use the Software;
				</p>
				<p>
				  (b) Any use of the Software contrary to the Documentation by the
				  User or any person authorised by the User to use the Software;
				</p>
				<p>
				  (c) A failure of the User to perform or observe any of its
				  obligations in this EULA; and/or
				</p>
				<p>
				  (d) Any incompatibility between the Software and any other system,
				  network, application, program, hardware or software not specified
				  as compatible in the Software Specification;
				</p>
				<p>
				  "<strong>Specification</strong>" means the specification for the
				  Software set out in the Documentation;
				</p>
				<p>
				  "<strong>Source Code</strong>" means the software code in
				  human-readable form or any part of the software code in
				  human-readable form, including code compiled to create the
				  Software or decompiled from the Software, but excluding
				  interpreted code comprised in the Software;
				</p>
				<p>
				  "<strong>Support Services</strong>" means support in relation to
				  the use of the Software and the identification and resolution of
				  errors in the Software, but shall not include the provision of
				  training services whether in relation to the Software or
				  otherwise;
				</p>
				<p>
				  "<strong>Term</strong>" means the term of this EULA, commencing in
				  accordance with Clause 2.1 and ending in accordance with Clause
				  2.2;
				</p>
				<p>
				  "<strong>Update</strong>" means a hotfix, patch or minor version
				  update to the Software;
				</p>
				<p>
				  "<strong>Upgrade</strong>" means a major version upgrade of the
				  Software;
				</p>
				<p>
				  "<strong>User</strong>" means the person to whom the Licensor
				  grants a right to use the Software under this EULA; and
				</p>
				<p>
				  "<strong>User Indemnity</strong>" has the meaning given to it in
				  Clause 9.3.
				</p>
				<p>
				  <strong>2. </strong>
				  <strong>Term</strong>
				</p>
				<p>2.1 This EULA shall come into force upon the Effective Date.</p>
				<p>
				  2.2 This EULA shall continue in force for an infinite term,
				  subject to termination in accordance with Clause 10.
				</p>
				<p>
				  <strong>3. </strong>
				  <strong>License:</strong>
				</p>
				<p>
				  3.1 The Licensor hereby grants to the User from the date of supply
				  of the Software to the User until{" "}
				  <strong>the end of the Term </strong>a limited, non-exclusive,
				  non-transferable, revocable license, without the right of
				  sublicense license to:
				</p>
				<p>
				  (a) Install the Software in a PC, Tablet, Laptop Mobile Phone, or
				  any other personal computing device under your control;
				</p>
				<p>
				  (b) Use the Software in accordance with the Documentation; and
				  subject to the limitations and prohibitions set out and referred
				  to in this Clause 3.
				</p>
				<p>
				  3.2 The User may not sub-license and must not purport to
				  sub-license any rights granted under Clause 3.1.
				</p>
				<p>
				  3.3 Save to the extent expressly permitted by this EULA or
				  required by applicable law on a non-excludable basis, any licence
				  granted under this Clause 3 shall be subject to the following
				  prohibitions:
				</p>
				<p>
				  (a) The User must not sell, resell, rent, lease, loan, supply,
				  publish, distribute or redistribute the Software;
				</p>
				<p>(b) The User must not alter, edit or adapt the Software; and</p>
				<p>
				  (c) The User must not decompile, de-obfuscate or reverse engineer,
				  or attempt to decompile, de-obfuscate or reverse engineer, the
				  Software.
				</p>
				<p>
				  3.4 The User shall be responsible for the security of copies of
				  the Software supplied to the User under this EULA and shall use
				  all reasonable endeavours (including all reasonable security
				  measures) to ensure that access to such copies is restricted to
				  persons authorized (Authorized Users) to use them under this EULA
				  and to be used on supported devices and not on any other device.
				</p>
				<p>
				  <strong>4. </strong>
				  <strong>Source Code</strong>
				</p>
				<p>
				  4.1 Nothing in this EULA shall give to the User or any other
				  person any right to access or use the Source Code or constitute
				  any licence of the Source Code.
				</p>
				<p>
				  <strong>5. </strong>
				  <strong>Maintenance and Support Services</strong>
				</p>
				<p>
				  5.1 Subject to Licensee payment of the Maintenance and Support
				  fee, the Licensor shall provide the Maintenance and Support
				  Services to the User during the Term.
				</p>
				<p>
				  5.2 The Licensor may suspend the provision of the Maintenance and
				  Support Services if any amount due to be paid by the User to the
				  Licensor under this EULA is overdue, and the Licensor has given to
				  the User at least [30 days'] written notice, following the amount
				  becoming overdue, of its intention to suspend the Maintenance and
				  Support Services on this basis.
				</p>
				<p>
				  5.3 If the Licensor stops or makes a good faith decision to stop
				  providing maintenance and support services in relation to the
				  Software to its customers generally, then the Licensor may
				  terminate the Maintenance and Support Services by giving at least
				  [90 days'] written notice of termination to the User.
				</p>
				<p>
				  5.4 If the Maintenance and Support Services are terminated in
				  accordance with the provisions of this Clause 5:
				</p>
				<p>
				  (a) The User must pay to the Licensor any outstanding Charges in
				  respect of Maintenance and Support Services provided to the User
				  before the termination of the Maintenance and Support Services;
				</p>
				<p>
				  (b) the Licensor must refund to the User any Charges paid by the
				  User to the Licensor in respect of Maintenance and Support
				  Services that were to be provided to the User after the
				  termination of the Maintenance and Support Services; and
				</p>
				<p>
				  (c) the provisions of this Clause 5, excluding this Clause 5.5,
				  shall cease to apply, but the other provisions of this EULA will
				  continue notwithstanding such termination.
				</p>
				<p>
				  <strong>6. </strong>
				  <strong>No assignment of Intellectual Property Rights</strong>
				</p>
				<p>
				  6.1 Nothing in this EULA shall operate to assign or transfer any
				  Intellectual Property Rights from the Licensor to the User, or
				  from the User to the Licensor.
				</p>
				<p>
				  <strong>7. </strong>
				  <strong>Charges</strong>
				</p>
				<p>
				  7.1 The User shall pay the Charges to the Licensor in accordance
				  with this EULA.
				</p>
				<p>
				  7.2 All amounts stated in or in relation to this EULA are, unless
				  the context requires otherwise, stated exclusive of any applicable
				  value added taxes, which will be added to those amounts and
				  payable by the User to the Licensor.
				</p>
				<p>
				  <strong>8. </strong>
				  <strong>Acknowledgements and warranty limitations</strong>
				</p>
				<p>
				  8.1 The User acknowledges that Software is not fully free from
				  defects, errors and bugs; and subject to the other provisions of
				  this EULA, the Licensor except as specifically provided under the
				  Order Form, gives no other warranty or representation that the
				  Software will be wholly free from defects, errors and bugs.
				</p>
				<p>
				  8.2 The User acknowledges that Software is not fully free from
				  security vulnerabilities; and subject to the other provisions of
				  this EULA, the Licensor gives no warranty or representation that
				  the Software will be entirely secure.
				</p>
				<p>
				  8.3 The User acknowledges that the Software is only designed to be
				  compatible with that software specified as compatible in the
				  Software Specification; and the Licensor does not warrant or
				  represent that the Software will be compatible with any other
				  software.
				</p>
				<p>
				  8.4 The User acknowledges that the Licensor will not provide any
				  [legal, financial, accountancy or taxation advice] under this EULA
				  or in relation to the Software; and, except to the extent
				  expressly provided otherwise in this EULA, the Licensor does not
				  warrant or represent that the Software or the use of the Software
				  by the User will not give rise to any legal liability on the part
				  of the User or any other person.
				</p>
				<p>8.5 Data Policy:</p>
				<p>
				  • If the User does backup of the data in cloud, Licensor has the
				  right to store it in the cloud in encrypted format,to secure the
				  data at rest and transit;
				</p>
				<p>
				  • Licensor will store User data and keep it available as long as
				  User uses the app;
				</p>
				<p>
				  • Licensor cannot guarantee data recovery, in the event of a
				  accidental uninstall of app, or data from the app by the user, if
				  the user has not done a cloud backup.
				</p>
				<p>
				  • Licensor will do not share User data with any third party except
				  for the purpose as stated in this EULA.
				</p>
				<p>
				  <strong>9. </strong>
				  <strong>Indemnities</strong>
				</p>
				<p>
				  9.1 The Licensor shall indemnify and shall keep indemnified the
				  User against any and all liabilities, damages, losses, costs and
				  expenses (including legal expenses and amounts reasonably paid in
				  settlement of legal claims) suffered or incurred by the User and
				  arising directly from a third party claim, as a result of any
				  breach by the Licensor of this EULA (a "
				  <strong>Licensor Indemnity</strong>").
				</p>
				<p>
				  The Licensor warrants to the User that the Software, when used by
				  the User in accordance with this EULA, will not infringe the
				  Intellectual Property Rights of any person under Indian law. If
				  the Licensor reasonably determines, or any third party alleges,
				  that the use of the Software by the User in accordance with this
				  EULA infringes any person's Intellectual Property Rights, the
				  Licensor may acting reasonably at its own cost and expense:
				</p>
				<p>
				  (a) modify the Software in such a way that it no longer infringes
				  the relevant Intellectual Property Rights, providing that any such
				  modification must not introduce any Software Defects into the
				  Software and must not result in the Software failing to conform
				  with the Software Specification; or
				</p>
				<p>
				  (b) procure for the User the right to use the Software in
				  accordance with this EULA.
				</p>
				<p>9.2 The User must:</p>
				<p>
				  (a) Upon becoming aware of an actual or potential Licensor
				  Indemnity Event, notify the Licensor;
				</p>
				<p>
				  (b) Provide to the Licensor all such assistance as may be
				  reasonably requested by the Licensor in relation to the Licensor
				  Indemnity Event;
				</p>
				<p>
				  (c) Allow the Licensor the exclusive conduct of all disputes,
				  proceedings, negotiations and settlements with third parties
				  relating to the Licensor Indemnity Event; and
				</p>
				<p>
				  (d) Not admit liability to any third party in connection with the
				  Licensor Indemnity Event or settle any disputes or proceedings
				  involving a third party and relating to the Licensor Indemnity
				  Event without the prior written consent of the Licensor.
				</p>
				<p>
				  9.3 The User shall indemnify and shall keep indemnified the
				  Licensor against any and all liabilities, damages, losses, costs
				  and expenses (including legal expenses and amounts reasonably paid
				  in settlement of legal claims) suffered or incurred by the
				  Licensor and arising directly or indirectly as a result of any
				  breach by the User of this EULA (a{" "}
				  <strong> "User Indemnity Event"</strong>).
				</p>
				<p>
				  9.4 The indemnity protection set out in this Clause 9 shall be
				  subject to the limitations and exclusions of liability set out in
				  this EULA.
				</p>
				<p>
				  <strong>10. </strong>
				  <strong>Limitations and exclusions of liability</strong>
				</p>
				<p>10.1 Nothing in this EULA will:</p>
				<p>
				  (a) Limit or exclude any liability for death or personal injury
				  resulting from gross negligence;
				</p>
				<p>(b) Limit or exclude any liability for fraud; or</p>
				<p>
				  (c) Exclude any liabilities that may not be excluded under
				  applicable law.
				</p>
				<p>
				  10.2 In no event will Licensor be liable to either User under or
				  in connection with this EULA for any indirect, incidental,
				  special, economic or consequential loss or damage, loss of
				  revenue, profits, goodwill, bargain or opportunities or loss of
				  anticipated savings incurred or suffered User whether in an action
				  in contract, tort statute or otherwise and whether or not Licensor
				  was aware or should have been aware of the possibility of such
				  loss or damage.
				</p>
				<p>
				  10.3 The Licensor will not be liable to the User in respect of any
				  loss or corruption of any data, database or software.
				</p>
				<p>
				  10.4 The liability of the Licensor to the User under this EULA in
				  respect of any event or series of related events shall not exceed
				  the total amount paid by the User to the Licensor under this EULA
				  in the last three (3) months period preceding the commencement of
				  the event or events which gave rise to the claim.
				</p>
				<p>
				  <strong>11. </strong>
				  <strong>Termination</strong>
				</p>
				<p>
				  11.1 The Licensor may terminate this EULA for any reason
				  whatsoever by giving the User immediate notice of termination.
				</p>
				<p>
				  11.2 Either party may terminate this EULA immediately by giving
				  written notice of termination to the other party if:
				</p>
				<p>
				  (a) the other party commits any material breach of this EULA, and
				  the breach is not remediable;
				</p>
				<p>
				  (b) the other party commits a breach of this EULA, and the breach
				  is remediable but the other party fails to remedy the breach
				  within the period of 30 days following the giving of a written
				  notice to the other party requiring the breach to be remedied; or
				</p>
				<p>
				  (c) the other party persistently breaches this EULA (irrespective
				  of whether such breaches collectively constitute a material
				  breach).
				</p>
				<p>
				  11.3 Either party may terminate this EULA immediately by giving
				  written notice of termination to the other party if:
				</p>
				<p>(a) The other party:</p>
				<p>(i) Is dissolved;</p>
				<p>
				  (ii) Ceases to conduct all (or substantially all) of its business;
				</p>
				<p>(iii) Is or becomes unable to pay its debts as they fall due;</p>
				<p>(iv) Is or becomes insolvent or is declared insolvent; or</p>
				<p>
				  (v) convenes a meeting or makes or proposes to make any
				  arrangement or composition with its creditors;
				</p>
				<p>
				  (b) An administrator, administrative receiver, liquidator,
				  receiver, trustee, manager or similar is appointed over any of the
				  assets of the other party;
				</p>
				<p>
				  (c) an order is made for the winding up of the other party, or the
				  other party passes a resolution for its winding up (other than for
				  the purpose of a solvent company reorganisation where the
				  resulting entity will assume all the obligations of the other
				  party under this EULA); or
				</p>
				<p>
				  11.4 The Licensor may terminate this EULA immediately by giving
				  written notice to the User if:
				</p>
				<p>
				  (a) Any amount due to be paid by the User to the Licensor under
				  this EULA is unpaid by the due date and remains unpaid upon the
				  date that that written notice of termination is given.
				</p>
				<p>
				  <strong>12. </strong>
				  <strong>Effects of termination</strong>
				</p>
				<p>
				  12.1 Upon the termination of this EULA, all of the provisions of
				  this EULA shall cease to have effect, save that the following
				  provisions of this EULA shall survive and continue to have effect
				  (in accordance with their express terms or otherwise
				  indefinitely): [Clauses 1, 3, 6, 7 and 10].
				</p>
				<p>
				  12.2 Except to the extent that this EULA expressly provides
				  otherwise, the termination of this EULA shall not affect the
				  accrued rights of either party.
				</p>
				<p>
				  12.3 Upon termination the User's rights will automatically expire.
				  In this event, User must pay all outstanding charges and all data
				  storage material and all copies of Software are to be destroyed.
				  This right is without prejudice to the Licensor’s other legal
				  rights.
				</p>
				<p>
				  12.4 For the avoidance of doubt, the licenses of the Software in
				  this EULA shall terminate upon the termination of this EULA; and,
				  accordingly, the User must immediately cease to use the Software
				  upon the termination of this EULA.
				</p>
				<p>
				  12.5 Immediately upon termination of this EULA, the User must:
				</p>
				<p>
				  (a) return to the Licensor or dispose of as the Licensor may
				  instruct all media in its possession or control containing the
				  Software; and
				</p>
				<p>
				  (b) Irrevocably delete from all computer systems in its possession
				  or control all copies of the Software.
				</p>
				<p>
				  <strong>13. </strong>
				  <strong>General</strong>
				</p>
				<p>
				  13.1 No breach of any provision of this EULA shall be waived
				  except with the express written consent of the party not in
				  breach.
				</p>
				<p>
				  13.2 If any provision of this EULA is determined by any court or
				  other competent authority to be unlawful and/or unenforceable, the
				  other provisions of this EULA will continue in effect. If any
				  unlawful and/or unenforceable provision would be lawful or
				  enforceable if part of it were deleted, that part will be deemed
				  to be deleted, and the rest of the provision will continue in
				  effect (unless that would contradict the clear intention of the
				  parties, in which case the entirety of the relevant provision will
				  be deemed to be deleted).
				</p>
				<p>
				  13.3 This EULA may not be varied except by a written document
				  signed by or on behalf of each of the parties.
				</p>
				<p>
				  13.4 Neither party may without the prior written consent of the
				  other party assign, transfer, charge, license or otherwise deal in
				  or dispose of any contractual rights or obligations under this
				  EULA.
				</p>
				<p>
				  13.5 This EULA is made for the benefit of the parties, and is not
				  intended to benefit any third party or be enforceable by any third
				  party. The rights of the parties to terminate, rescind, or agree
				  any amendment, waiver, variation or settlement under or relating
				  to this EULA are not subject to the consent of any third party.
				</p>
				<p>
				  13.6 This EULA shall constitute the entire agreement between the
				  parties in relation to the subject matter of this EULA, and shall
				  supersede all previous agreements, arrangements and understandings
				  between the parties in respect of that subject matter.
				</p>
				<p>
				  13.7 This EULA shall be governed by and construed in accordance
				  with laws of India.
				</p>
				<p>
				  13.8 The courts of Pune shall have exclusive jurisdiction to
				  adjudicate any dispute arising under or in connection with this
				  EULA.
				</p>
			  </div>
			</div>
		
	  );
}
