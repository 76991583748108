import ImageComponent from "../../utils/ImageComponent";
import FeatureAreaCard from "./FeatureAreaCard";

export default function FeatureArea() {
  return (
    <div className="feature_area">
      <div className="container">
        <h2 className="text-center">Everything you'll ever need</h2>
        <div className="row">
          <div className="col-lg-4 col-md-6 mt_30">
            <div className="box_wrap">
              <FeatureAreaCard
                image="img/sleekdesign.svg"
                heading="Sleek Design"
                paragraph=" Pocket Ledger is designed with mimimalist design concept for
      an awesome experience."
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt_30">
            <div className="box_wrap">
              <FeatureAreaCard
                image="img/inventory.svg"
                heading="Inventory Management"
                paragraph="  Manage your inventory, scan barcodes of items and create
                orders from them."
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt_30">
            <div className="box_wrap">
              <FeatureAreaCard
                image="img/seo-report.svg"
                heading="Save Receipts,Invoices and Reports in Cloud"
                paragraph="Pocket Ledger saves your receipts invoices and reports in
                cloud so that you will never loose them."
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt_30">
            <div className="box_wrap">
              <FeatureAreaCard
                image="img/bar-chart.svg"
                heading="Excel and Pdf reports"
                paragraph="Create Microsoft Excel or Adobe PDF report for your entries
                and create party specific reports too."
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt_30">
            <div className="box_wrap">
              <FeatureAreaCard
                image="img/invoice.svg"
                heading="Beautiful Invoices for your business"
                paragraph="Create beautiful invoice from list of templates for your
                customers and clients."
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt_30">
            <div className="box_wrap">
              <FeatureAreaCard
                image="img/database.svg"
                heading="Store and Secure Your Data"
                paragraph="With pocket Ledger you store and secure your data in the
                cloud.Industry standard encryption on your data to keep it
                secure. We store your data in Microsoft cloud"
              />
            </div>
          </div>
        </div>
      </div>
      <ImageComponent src="img/icon3.svg" className="icon1" />
    </div>
  );
}
