import ImageComponent from "../../utils/ImageComponent";

export default function FeaturesModalCarousel() {
  return (
    <>
      <div
        id="mySlideshow"
        className="carousel slide carousel-fade mb-30 width=400"
        data-ride="carousel"
      >
        {/* <!-- Indicators --> */}
        <ul className="carousel-indicators">
          <li data-target="#mySlideshow" data-slide-to="0"></li>
          <li data-target="#mySlideshow" data-slide-to="1"></li>
          <li data-target="#mySlideshow" data-slide-to="2"></li>
          <li data-target="#mySlideshow" data-slide-to="3"></li>
          <li data-target="#mySlideshow" data-slide-to="4"></li>
          <li data-target="#mySlideshow" data-slide-to="5"></li>
          <li data-target="#mySlideshow" data-slide-to="6"></li>
        </ul>

        <div className=" carousel-inner">
          <div className="carousel-item active">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent src="img/sleekdesign.svg" />
                  </div>
                </div>

                <div className="m-5">
                  <h2>Elegant UI</h2>
                  <h4>
                    Pocket Ledger is designed with mimimalist design concept for
                    an awesome experience.
                  </h4>
                </div>

                <div className="list-group list-group-mine  w-70 mx-auto justify-content-start">
                  <a href="#" className="list-group-item">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    Easy access to the data you need most
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    Access to most important features at your fingertip
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    Very performant to save you time so that you can manage your
                    business fast
                  </a>
                </div>

                {/* <!-- <a href="#" className="button2">View</a> --> */}
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent
                      src="img/invoice.svg"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="m-5">
                  <h2>Business Entries</h2>
                  <h4>
                    Manage you daily business transactions, like debit and
                    credit entries.
                  </h4>
                </div>

                <div className="list-group list-group-mine  w-70 mx-auto">
                  <a href="#" className="list-group-item">
                    <i className="fa fa-bolt" aria-hidden="true"></i>
                    Quickly create your entries for your parties
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    Save receipts to cloud automatically so you never loose them
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-percent" aria-hidden="true"></i>
                    Add interest accumulation on debit entries, and pocket
                    ledger will calculate them for you
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                    Add reminders for payment or print all entries to share and
                    many more features
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent
                      src="img/invoice.svg"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="m-5">
                  <h2>Invoices and Purchase Orders</h2>
                  <h4>
                    Create invoices for your services and purchase orders for
                    your business.Share them directly from app
                  </h4>
                </div>

                <div className="list-group list-group-mine  w-70 mx-auto">
                  <a href="#" className="list-group-item">
                    <i className="fa fa-bolt" aria-hidden="true"></i>
                    Quickly create invoices for your services and goods
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    Save invoices,purchase orders to cloud automatically so you
                    never loose them
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-percent" aria-hidden="true"></i>
                    Share your invoices,purchase orders directly with your
                    parties
                  </a>
                  <a href="#" className="list-group-item">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                    Choose one of many beautiful templates for your invoice
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent
                      src="img/invoice.svg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <h4>Inventory Management</h4>
                <p>Create awesome invoice for your customers and clients.</p>
                {/* <!-- <a href="#" className="button2">View</a> --> */}
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent
                      src="img/invoice.svg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <h4>Reporting</h4>
                <p>Create awesome invoice for your customers and clients.</p>
                {/* <!-- <a href="#" className="button2">View</a> --> */}
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent
                      src="img/database.svg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <h4>Store and Secure Your Data</h4>
                <p>
                  With pocket Ledger you store and secure your data in the
                  cloud.Industry standard encryption on your data to keep it
                  secure. We store your data in Microsoft cloud
                </p>
                <a href="#" className="button2">
                  View
                </a>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="box_wrap">
              <div className="box_content">
                <div className="icon_wrap mx-auto">
                  <div className="icon_box">
                    <ImageComponent
                      src="img/invoice.svg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <h4>Inventory Management</h4>
                <p>Create awesome invoice for your customers and clients.</p>
                {/* <!-- <a href="#" className="button2">View</a> --> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Left and right controls --> */}
        <a
          className="carousel-control-prev"
          href="#mySlideshow"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#mySlideshow"
          data-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
    </>
  );
}
