import { Link } from "react-router-dom";
import FooterSocialIcons from "./FooterSocialIcons";
import FooterContactUs from "./FooterContactUs";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <FooterSocialIcons />
          <FooterContactUs />
        </div>
      </footer>
    </>
  );
}
