import ImageComponent from "../utils/ImageComponent";
import ContactNumbers from "./ContactNumbers";

export default function FooterContactUs() {
  return (
    <div id="contactus" className="footer_bottom">
      <div className="middle_part">
        <div className="row align-items-center">
          <div className="col-lg-4 mt_15">
            <div className="d-inline-flex align-items-center mr_40">
            </div>
          </div>
          <div className="col-lg-4">
            <div className="alert fade show">
              <p>
                By using this site you agree to our
                <strong>Cookie Policy</strong>
              </p>
              <button type="button" className="close" data-dismiss="alert">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div className="col-lg-4 text-lg-right mt_15">
            <p>© Copyright 2021</p>
          </div>
        </div>
      </div>
      <div className="bottom_part">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4">
            <div className="d-inline-flex icon_text align-items-center mt_15">
              <ImageComponent src="img/ficon3.svg" />
              <p>
                <b>Assam,India & British Columbia,Canada</b>
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <ContactNumbers
              image="img/ficon4.svg"
              url="tel:+919163954634"
              number="+91-9163954634"
            />
          </div>
          <div className="col-lg-4 text-lg-right">
            <div className="d-inline-flex icon_text align-items-center mt_15">
              <ImageComponent src="img/ficon6.svg" />
              <a href="mailto:support@eeighttech.com">
                <p>
                  <b>support@eeighttech.com</b>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
