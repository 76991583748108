import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Main from "../components/Main/Main";
import FeaturesModal from "../components/Modals/FeaturesModal/FeaturesModal";
import PricesModal from "../components/Modals/PricesModal";
import TNCModal from "../components/Modals/TNCModal";
import BackToTopButton from "../components/utils/BackToTopButton";
import PreLoader from "../components/utils/PreLoader";

export default function Home() {
  return (
    <>
      {/* <!-- Header --> */}
      <Header />

      {/* <!-- main area --> */}
      <Main />

      {/* <!-- footer area --> */}
      <Footer />

      {/* <!-- Modals --> */}

      <FeaturesModal />
      <PricesModal />
      <TNCModal />

      {/* <!-- BACK TO TOP START --> */}
      <BackToTopButton />
    </>
  );
}
