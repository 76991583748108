import ImageComponent from "../../utils/ImageComponent";
import ModalCloseButton from "../ModalCloseButton";
import FeaturesModalCarousel from "./FeaturesModalCarousel";

export default function FeaturesModal() {
  return (
    <>
      <div className="modal fade" id="features" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h3 className="modal-title w-100">Features</h3>
              <ModalCloseButton />
            </div>
            <ImageComponent src="img/line.png" className="w-100 line" />

            <div className="container mt-30 mb-30">
              <FeaturesModalCarousel />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
