import ImageComponent from "../utils/ImageComponent";
import ModalCloseButton from "./ModalCloseButton";

export default function PricesModal() {
  return (
    <div className="modal fade" id="pricing" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h3 className="modal-title w-100">Pricing</h3>
            <ModalCloseButton />
          </div>
          <img src="img/line.png" className="w-100 line" alt="" />
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6 mt_30">
              <div className="box_wrap ">
                <div className="box_content">
                  <div className="icon_wrap mx-auto">
                    <div className="icon_box">
                      <ImageComponent src="img/sleekdesign.svg" />
                    </div>
                  </div>
                  <h4>Free Version</h4>
                  <p>
                    You get all features in free version too. The only
                    restriction is on the number of entities you can create.
                  </p>
                  {/* <!-- <a href="#" className="button2">View</a> --> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt_30">
              <div className="box_wrap">
                <div className="box_content">
                  <div className="icon_wrap mx-auto">
                    <div className="icon_box">
                      <ImageComponent
                        src="img/sleekdesign.svg"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <h4>Paid Version</h4>
                  <p>
                    With paid version you get unlimited data storage limits. All
                    your business entries , reports, Invoices , Purchase orders,
                    Receipts images are stored in cloud. Multiple devices are
                    allowed to access the data and is stored for lifetime.
                  </p>
                  <p>
                    You have the option to go <b>monthly</b> or purchase
                    <b>yearly plan</b> with a discount.
                  </p>
                  {/* <!-- <a href="#" className="button2">View</a> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
