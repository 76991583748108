import { useState } from "react";

export default function WhiteListArea() {
  const [email, setEmail] = useState("");
  const currentTime = Math.floor(Date.now() / 1000);


  return (
    <div className="whitelist_area">
      <div className="container">
        <div className="whitelist_box">
          <h2>We care whenever you need it</h2>
          <p>
            Join our notification list to get latest release news and updates.
          </p>
          <form action="#">
            <div className="w-100">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your mail"
              />
            </div>
            <button
              type="submit"
              className="btn3"
              // onClick={(e) => handleSubmit(e)}
            >
              Get Notified
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
