import { Link } from "react-router-dom";
import Logo from "./Logo";

import { HashLink } from "react-router-hash-link";

export default function Header() {
  return (
    <>
      <header>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-4 col-6 text-left">
              {/* --logo-- */}
              <Logo />
            </div>
            <div className="col-lg-9 col-md-8 col-6 text-right">
              {/* --navigation links--*/}
              <ul id="menu">
                <li className="nav-item">
                  <a
                    href="/"
                    data-toggle="modal"
                    data-target="#features"
                    className="nav-link"
                  >
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/"
                    data-toggle="modal"
                    data-target="#pricing"
                    className="nav-link"
                  >
                    Pricing
                  </a>
                </li>

                <li className="nav-item">
                  <HashLink smooth to="#contactus" className="nav-link">
                    Contact us
                  </HashLink>
                </li>
                <li className="nav-item">
                  <a
                    href="/"
                    data-toggle="modal"
                    data-target="#tnc"
                    className="nav-link"
                  >
                    Terms & Conditions
                  </a>
                </li>
              </ul>

              {/* -- menu -- */}
              <div className="hamburger-menu">
                <span className="line-top"></span>
                <span className="line-center"></span>
                <span className="line-bottom"></span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
