import { Link } from "react-router-dom";
import ImageComponent from "../utils/ImageComponent";

export default function HomeArea() {
  return (
    <>
      <div className="home_area">
        <div className="container text-center">
          <h1>The Smart Business Ledger App</h1>
          <p className="mt_15 mx-auto">
            We understand that small business needs special tools to run. We
            also understand that small business need cost effective solutions
            and therefore we have designed and created something which is simple
            and yet powerfull.
          </p>
          <Link
            to="https://play.google.com/store/apps/details?id=os.pokledlite"
            className="button mt_30"
          >
            Start for free
          </Link>
          <div className="mobile_wrap mx-auto">
            <div className="row align-items-center">
              <div className="col">
                <ImageComponent src="img/img1.png" className="w-100" />
              </div>
              <div className="col">
                <ImageComponent src="img/img2.png" className="w-100" />
              </div>
              <div className="col">
                <ImageComponent src="img/img3.png" className="w-100" />
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col">
                <Link to="https://apps.apple.com/us/app/pocket-ledger/id6463052653">
                  <ImageComponent src="img/appstore.png" className="w-100" />
                </Link>
              </div>
              <div className="col">
              <Link to="https://play.google.com/store/apps/details?id=os.pokledlite">
                  <ImageComponent src="img/gplay.png" className="w-100" />
                </Link>
              </div>
            </div>

          </div>
        </div>
        <ImageComponent src="img/line.png" className="w-100 line" />
      </div>
    </>
  );
}
