import "../components/DeleteAccount/DeleteAccountPage.css";
import AccountDeleteRequestForm from "../components/DeleteAccount/AccountDeleteRequestForm";

export default function DeleteAccount() {
	return (
		<>
			<div className="delete-account-main-wrapper">
				<div className="d-flex flex-column deleteAccountTop">
					<h2 className="pb-3 text-center">Account Delete Request</h2>
					<div style={{ height: "4px", backgroundColor: "red" }}></div>
				</div>

				<div className="delete-account-content-wrapper container">
					<p className="pt-4 delete-account-desc">
						We are sorry to see you go.However since you have decided to leave
						our service and would like to delete your account you can do it in 2
						ways.
					</p>
					<div className="row  pt-5">
						<div className=" col-lg-6">
							<div className="d-flex pb-5">
								<p
									style={{ width: "40px", height: "40px" }}
									className="d-flex align-items-center justify-content-center text-white bg-dark px-2 rounded-circle"
								>
									<span style={{ fontSize: "2rem", fontWeight: "bolder" }}>
										1
									</span>
								</p>
								<div className="w-100 pl-3">
									<div className="pb-5">
										<h3>In App Account Deletion process</h3>
										<p style={{ color: "lightgray" }}>
											You will be signed out of the app and your data will be
											deleted immediately
										</p>
									</div>
									<div>
										<img
											src="img/deleteAccountMobileImage.jpg"
											className="mobile-mock-up"
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
						<div className=" col-lg-6 mt-5 mt-lg-0">
							<div className=" pb-5 d-flex">
								<p
									style={{ width: "40px", height: "40px" }}
									className="d-flex align-items-center justify-content-center text-white bg-dark px-2 rounded-circle"
								>
									<span style={{ fontSize: "2rem", fontWeight: "bolder" }}>
										2
									</span>
								</p>
								<div className="pl-3 pt-3">
									<h3 className="pb-3">Account Deletion Request Form</h3>

									<AccountDeleteRequestForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
