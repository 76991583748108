import React from "react";
import { Link } from "react-router-dom";
import ImageComponent from "../utils/ImageComponent";

export default function Logo() {
  return (
    <Link to="#">
      <ImageComponent src="/img/logo.svg" className="img-fluid" />
    </Link>
  );
}
