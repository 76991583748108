import React, { useEffect, useState } from "react";
import { deleteUser } from "../../api/delete-user";
import { getErrorMessage } from "../../utils/errors";

export default function AccountDeleteRequestForm() {
	const [email, setEmail] = useState("");
	const [pin, setPin] = useState("");
	const [status, setStatus] = useState({
		show: false,
		loading: false,
		success: true,
		errorMsg: "",
	});

	const handleDeleteAccountButton = async (e: React.FormEvent) => {
		e.preventDefault();

		if (email.trim() && pin.trim()) {
			try {
				const data = { email, pin };
				setStatus((prev) => ({ ...prev, loading: true }));
				const res = await deleteUser(data);
				if (res.status !== 200) {
					const data = await res.json();
					return setStatus((prev) => ({
						...prev,
						success: false,
						errorMsg: getErrorMessage(data.errorCode ?? ""),
					}));
				} else {
					setStatus((prev) => ({ ...prev, success: true, errorMsg: "" }));
					clearFormHandler();
				}
			} catch (err) {
				setStatus((prev) => ({
					...prev,
					success: false,
					errorMsg: getErrorMessage(""),
				}));
			} finally {
				setStatus((prev) => ({ ...prev, show: true, loading: false }));
			}
		}
	};

	const clearStatusHandler = () => {
		setStatus({ show: false, success: false, errorMsg: "", loading: false });
	};

	const clearFormHandler = () => {
		setEmail("");
		setPin("");
	};

	useEffect(() => {
		if (status.show) {
			setTimeout(clearStatusHandler, 5000);
		}
	}, [status.show]);

	return (
		<>
			<form className="pt-4" onSubmit={(e) => handleDeleteAccountButton(e)}>
				<fieldset>
					<div className="mb-3">
						<label htmlFor="exampleFormControlInput1" className="form-label">
							Enter your user id(Email or Phone number)
						</label>
						<input
							type="email"
							className="form-control form-control-lg"
							id="exampleFormControlInput1"
							value={email}
							required
							onChange={(e) => setEmail(e.target.value)}
							placeholder=""
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="exampleFormControlInput1" className="form-label">
							Enter your pin
						</label>
						<input
							type="text"
							required
							className="form-control w-50 form-control-lg"
							id="exampleFormControlInput1"
							value={pin}
							onChange={(e) => setPin(e.target.value)}
						/>
					</div>

					<button
						type="submit"
						className="btn mt-3 btn-dark rounded-pill p-2 px-3"
					>
						<h4 className="d-flex align-items-center">
							{status.loading && <div className="loader mr-2"></div>}
							<span className="text-nowrap my-2">Delete Account</span>
						</h4>
					</button>

					{status.show && (
						<div className="status-msg">
							{status.success ? (
								<p className="success">You account is now deleted successfully</p>
							) : (
								<p className="error">{status.errorMsg}</p>
							)}
						</div>
					)}
				</fieldset>
			</form>
		</>
	);
}
