import ImageComponent from "../../utils/ImageComponent";
import TutorialAreaSocialIcons from "./TutorialAreaSocialIcons";

type TutorialAreaCardProps = {
  image: string;
  url: string;
  playImage: string;
  date: string;
  heading: string;
  subHeading: string;
};

export default function TutorialAreaCard(props: TutorialAreaCardProps) {
  return (
    <div className="row align-items-center">
      <div className="col-md-6 order-md-2">
        <div className="video_thumbnail">
          <ImageComponent src={props.image} />

          <a className="play-video" href={props.url}>
            <ImageComponent src={props.playImage} />
          </a>
        </div>
      </div>
      <div className="col-md-6 order-md-1">
        <div className="date">{props.date}</div>
        <h4>{props.heading}</h4>
        <p>{props.subHeading}</p>
        <TutorialAreaSocialIcons />
      </div>
    </div>
  );
}
